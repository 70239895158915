import { Routes, Route } from 'react-router-dom';
import './App.css';
import { Footer, Header } from './components';
import { Contact, Home, NotFound } from './pages';
import PrivacyPolicy from './pages/privacyPolicy/privacyPolicy.pages';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
