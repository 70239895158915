import React from 'react'
import './product.styles.css';
import { MOCK1, MOCK2, MOCK3, SNEAKLOGO } from '../../assets';
import { useInView } from 'react-intersection-observer';
const Product = () => {

  const { ref: productHeader, inView: headerVisible } = useInView({threshold: 1});
  const { ref: productDesp, inView: despVisible } = useInView({threshold: 1});

  const { ref: mockupBody, inView: MockUpVisible } = useInView({threshold: 0,})

  return (
    <div className='product-container'>
      <div className="product-sneak-app" id='sneak'>
        <div className={`${"product-header"} ${headerVisible ? "trans-from-buttom" : ""}`} ref={productHeader}>
          <img className='trans-rotate' src={SNEAKLOGO} alt="" />
          <h1 >SNEAK</h1>
        </div>
        <div ref={productDesp} className={`${"product-description"} ${despVisible ? "trans-from-right" :""}`}  >
          <p>Ad blocker, Bundled subscriptions, Anonymous Login and ONDC buyer app.</p>
        </div>
        <a href="https://sneek.in" target='__blank' className='try-sneak-btn-2'>
          Try Sneak
        </a>
        <div className={`${"product-mockups"} ${MockUpVisible ? "trans-from-bottom": ""}`} ref={mockupBody}>
          <img src={MOCK3} className='mockups mockups-1' alt="" />
          <img src={MOCK1} className='mockups mockups-2' alt="" />
          <img src={MOCK2} className='mockups mockups-3' alt="" />
        </div>
      </div>
    </div>
  )
}

export default Product