import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './header.styles.css';
import { LOGO, BURGERICON } from '../../assets';

const Header = () => {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const ToHome = () => {
        return navigate("/")
    }

    const HandlerClick = () => {
        const scrollTargetElement = document.getElementById("sneak")

        if (scrollTargetElement) {
            scrollTargetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }
    const HandleNav = (e) => {
        return navigate(e.target.value)
    }

    const HandleContact = () => {
        const scrollTargetElement = document.getElementById("contact")

        if (scrollTargetElement) {
            scrollTargetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <header className='trans-from-top'>
            <div className="left-header" onClick={ToHome}>
                <img src={LOGO} alt="" />
                <h3>Abblor</h3>
            </div>
            <div className="center-header">
                <ul className='nav-option'>
                    <li><button onClick={HandleContact} value={"/"} >Contact</button></li>
                </ul>
            </div>
            <div className="right-header">
                {/* <a href="/">info@abblor.com</a> */}
                <button className='show-products' onClick={HandlerClick} value={"/"} >Products</button>
            </div>

            <div className="burger dropdown-nav" onClick={toggleMenu}>
                <div className='dropdown-btn'>
                    <img src={BURGERICON} className='brg-icon' alt="" />
                </div>
                <div className="dropdown-content">
                    <button onClick={HandleNav} value={"/"}>🏠</button>
                    <button onClick={HandleContact} value={"/"}>Contact</button>
                    <button onClick={HandlerClick} value={"/"}>Product</button>
                </div>
            </div>
        </header>
    )
}

export default Header
