import React from "react";
import "./notified.styles.css";

const Notified = () => {
  return (
    <div className="notified-container" id="contact">
      <div className="notified-body">
        <h1>Be Painfully aware. 😜</h1>
        <p class="subscribe-tagline shimmer">
          Stay Informed, Stay Ahead: Be the First to Know and Stay on the
          Cutting Edge of Innovation!
        </p>

        <div id="mc_embed_shell">
          <div id="mc_embed_signup">
            <form
              action="https://abblor.us21.list-manage.com/subscribe/post?u=78401b59a214854f05d2d103f&amp;id=9e35a110b9&amp;f_id=0030e2e6f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate "
              target="_blank"
            >
              <div id="mc_embed_signup_scroll" class="subscribe-form">
                <div class="mc-field-group">
                  <label for="mce-EMAIL"></label>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <input
                      type="email"
                      name="EMAIL"
                      class="required email"
                      id="mce-EMAIL"
                      placeholder="Enter email address..."
                      require
                    />{" "}
                    <div class="optionalParent">
                      <div class="clear foot">
                        <input
                          type="submit"
                          name="subscribe"
                          id="mc-embedded-subscribe"
                          class="button subscribe-button"
                          value="Subscribe"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  hidden=""
                  style={{
                    visibility: "hidden",
                    width: "0px",
                    height: "0px",
                    position: "absolute",
                  }}
                >
                  <input type="hidden" name="tags" value="2822241"></input>{" "}
                </div>
                <div
                  id="mce-responses"
                  class="clear foot"
                  style={{
                    visibility: "hidden",
                    width: "0px",
                    height: "0px",
                    position: "absolute",
                  }}
                >
                  <div class="response" id="mce-error-response"></div>
                  <div class="response" id="mce-success-response"></div>
                </div>
                <div
                  aria-hidden="true"
                  style={{
                    visibility: "hidden",
                    width: "0px",
                    height: "0px",
                    position: "absolute",
                  }}
                >
                  {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                  <input
                    type="text"
                    name="b_78401b59a214854f05d2d103f_9e35a110b9"
                    tabindex="-1"
                    value=""
                    style={{ visibility: "hidden" }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notified;
