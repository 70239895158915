import React from 'react'
import './home.styles.css';
import { Hero, Product, Notified } from '../../components';

const Home = () => {
  return (
    <div className='home-page'>
        <Hero />
        <Product/>
        <Notified />
    </div>
  )
}

export default Home