import React from 'react'
import './hero.styles.css';
import { SCROLLDW } from '../../assets';

const Hero = () => {
  const HandlerClick = () => {
    const scrollTargetElement = document.getElementById("sneak")

    if (scrollTargetElement) {
        scrollTargetElement.scrollIntoView({ behavior: 'smooth' });
    }
}
  return (
    <div className='hero-container'>
      <div className="bg-design">
        <svg width="986" height="853" viewBox="0 0 986 853" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M346 198.5C2419.34 778.14 -1492.95 497.915 735.7 235.036" stroke="#BBBBBB" stroke-width="3" stroke-linecap="round" />
          <path className='circular-2' d="M961.569 444.212C960.766 459.12 948.33 473.748 923.852 487.129C899.6 500.387 864.661 511.766 821.412 520.63C734.973 538.346 616.255 545.834 485.792 538.806C355.33 531.778 238.103 511.58 154.067 484.681C112.02 471.222 78.5059 456.155 55.8186 440.369C32.9201 424.436 22.1271 408.556 22.9302 393.648C23.7333 378.74 36.1698 364.111 60.6473 350.73C84.8993 337.473 119.838 326.094 163.088 317.23C249.527 299.514 368.245 292.026 498.707 299.054C629.17 306.082 746.397 326.28 830.432 353.179C872.479 366.638 905.993 381.704 928.681 397.491C951.579 413.424 962.372 429.303 961.569 444.212Z" stroke="#BBBBBB" stroke-width="3" />
        </svg>

        {/* <img src={CIRCLE} alt="" /> */}
      </div>
      <div className="hero-contents trans-from-bottom">
        <h1>We are a  <span className='bolder green-b'>Privacy </span>
          and <span className='bolder red-b'>Security</span> company building products for Digital Inclusion and
          Safe internet browsing.</h1>
        <img src={SCROLLDW} className='scroll-down' onClick={HandlerClick} alt="" />
      </div>
    </div>
  )
}

export default Hero;