import React from 'react'
import { useNavigate } from 'react-router-dom'
import './notfound.styles.css'

const Notfound = () => {
	const navigate = useNavigate()

	const backToHome = () => {
		return navigate("/")
	}

	return (
		<div className='notfound-page'>
			<div class="text"><p>404</p></div>

			<div class="container">
				<div class="caveman">
					<div class="leg">
						<div class="foot"><div class="fingers"></div></div>
					</div>
					<div class="leg">
						<div class="foot"><div class="fingers"></div></div>
					</div>
					<div class="shape">
						<div class="circle"></div>
						<div class="circle"></div>
					</div>
					<div class="head">
						<div class="eye"><div class="nose"></div></div>
						<div class="mouth"></div>
					</div>
					<div class="arm-right"><div class="club"></div></div>
				</div>
				<div class="caveman">
					<div class="leg">
						<div class="foot"><div class="fingers"></div></div>
					</div>
					<div class="leg">
						<div class="foot"><div class="fingers"></div></div>
					</div>
					<div class="shape">
						<div class="circle"></div>
						<div class="circle"></div>
					</div>
					<div class="head">
						<div class="eye"><div class="nose"></div></div>
						<div class="mouth"></div>
					</div>
					<div class="arm-right"><div class="club"></div></div>

				</div>

			</div>
			<div className='back-to-home'>
				<h1>Look like you're lost</h1>
				<p>the page you are looking for not available!</p>
				<button className='back-to-home-btn' 
					onClick={backToHome}
				>
					Back to Home
				</button>
			</div>
		</div>
	)
}

export default Notfound