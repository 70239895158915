import React from 'react'
import './footer.styles.css';
import { LOGO, MAILLOGO } from '../../assets';

const Footer = () => {
    return (
        <div className='footer-container'>
            <div className="footer-contents">
                <div className="footer-line"></div>
                <div className="footer-sub-content1">
                    <div className="logo">
                        <img src={LOGO} className='footer-logo' alt="" />
                        <h3>Abblor</h3>
                    </div>
                    <div class="footer-links">
                        <a href="/privacy-policy" className='privacy-pol' >Privacy Policy</a>
                        <div className="connect">
                            <a href="/">info@abblor.com</a>
                        </div>
                    </div>
                </div>
                <div className="end-contents">
                    <p>
                        <span>© Copyright 2023.</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Footer